import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'kit/';

const config = {
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  },
  withCredentials: true,
  crossOrigin: true
}

class KitService {
  getKitsList() {
    return axios.post(API_URLA + 'getKitsList/', {}, config);
  }
  getKitsListRace() {
    return axios.post(API_URLA + 'getKitsListRace/', {}, config);
  }
  getKitsListRace8() {
    return axios.post(API_URLA + 'getKitsListRace8/');
  }
  getKitsListFight() {
    return axios.post(API_URLA + 'getKitsListFight/', {}, config);
  }
}

export default new KitService();
